﻿/*!
 * Redbook v1
 */

$primary: #aa0a24 !default;
$primary-dark: #910d23 !default;

@font-face {
    font-family: CSicons; 
    font-style: normal;
    font-weight: normal;
    src: url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.eot?4ujry3");
    src: url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.eot?4ujry3#iefix") format("embedded-opentype"), url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.ttf?4ujry3") format("truetype"), url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.woff?4ujry3") format("woff"), url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.svg?4ujry3#icomoon") format("svg");
}

.csn-theme {
    @import "functions";
    @import "variables";
    @import "mixins";
    @import "root";
    @import "reboot";
    margin: 0; // 1
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    color: $body-color;
    text-align: left; // 3
    background-color: $white; // 2
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @import "type";
    @import "icons";
    @import "images";
    @import "code";
    @import "grid";
    @import "tables";
    @import "buttons";
    @import "transitions";
    @import "badge";
    @import "utilities";
    @import "print";
    //components
    @import "forms";
    @import "dropdown";
    @import "button-group";
    @import "input-group";
    @import "custom-forms";
    @import "nav";
    @import "navbar";
    @import "card";
    @import "breadcrumb";
    @import "pagination";
    @import "jumbotron";
    @import "alert";
    @import "progress";
    @import "media";
    @import "list-group";
    @import "close";
    @import "tooltip";
    @import "modal";
    @import "popover";
    @import "carousel";
}
