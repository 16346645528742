[class^="icon-"],
[class*=" icon-"]  {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: CSicons, serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account::before {
  content: "\e900";
}
.icon-add-circle::before {
  content: "\e901";
}
.icon-add::before {
  content: "\e902";
}
.icon-arrow-down::before {
  content: "\e903";
}
.icon-arrow-left::before {
  content: "\e904";
}
.icon-arrow-right::before {
  content: "\e905";
}
.icon-arrow-up::before {
  content: "\e906";
}
.icon-body-type::before {
  content: "\e907";
}
.icon-calendar::before {
  content: "\e908";
}
.icon-call::before {
  content: "\e909";
}
.icon-camera::before {
  content: "\e90a";
}
.icon-circle-close::before {
  content: "\e90b";
}
.icon-circle-sell::before {
  content: "\e90c";
}
.icon-circle-tick::before {
  content: "\e90d";
}
.icon-close::before {
  content: "\e90e";
}
.icon-cog::before {
  content: "\e90f";
}
.icon-creditcard::before {
  content: "\e910";
}
.icon-detail-list::before {
  content: "\e911";
}
.icon-document::before {
  content: "\e912";
}
.icon-drive-type::before {
  content: "\e913";
}
.icon-edit::before {
  content: "\e914";
}
.icon-email::before {
  content: "\e915";
}
.icon-engine::before {
  content: "\e916";
}
.icon-external-link::before {
  content: "\e917";
}
.icon-fav-filled::before {
  content: "\e918";
}
.icon-favourite::before {
  content: "\e919";
}
.icon-filter::before {
  content: "\e91a";
}
.icon-finance::before {
  content: "\e91b";
}
.icon-home::before {
  content: "\e91c";
}
.icon-hrz-dots::before {
  content: "\e91d";
}
.icon-idea-bulb::before {
  content: "\e91e";
}
.icon-info::before {
  content: "\e91f";
}
.icon-location::before {
  content: "\e920";
}
.icon-lock::before {
  content: "\e921";
}
.icon-membenefits::before {
  content: "\e922";
}
.icon-mobile::before {
  content: "\e923";
}
.icon-notifications::before {
  content: "\e924";
}
.icon-odometer::before {
  content: "\e925";
}
.icon-play::before {
  content: "\e926";
}
.icon-price-drop::before {
  content: "\e927";
}
.icon-question::before {
  content: "\e928";
}
.icon-refresh::before {
  content: "\e929";
}
.icon-saved-favourites::before {
  content: "\e92a";
}
.icon-saved-searches::before {
  content: "\e92b";
}
.icon-search::before {
  content: "\e92c";
}
.icon-send::before {
  content: "\e930";
}
.icon-share::before {
  content: "\e931";
}
.icon-sms-message::before {
  content: "\e932";
}
.icon-star-filled::before {
  content: "\e933";
}
.icon-star-half-filled::before {
  content: "\e934";
}
.icon-star::before {
  content: "\e935";
}
.icon-tick::before {
  content: "\e936";
}
.icon-transmission::before {
  content: "\e937";
}
.icon-trash::before {
  content: "\e938";
}
.icon-video::before {
  content: "\e939";
}
.icon-vrt-dots::before {
  content: "\e93a";
}
.icon-warning::before {
  content: "\e93b";
}
.icon-bookmark::before {
  content: "\e93c";
}
